import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import { useAddToHomescreenPrompt } from "../../hooks/useAddToHomeScreenPrompt";

type Props = {};

const InstallModal: React.FC<Props> = (props: Props) => {
  const [modalShow, setModalShow] = React.useState(true);
  const [, promptToInstall] = useAddToHomescreenPrompt();
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Install as Mobile App
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you want to add this app to your home screen? This will allow you
          to use this app as a mobile app.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModalShow(false)}>Close</Button>
        <Button
          onClick={() => {
            setModalShow(false);
            promptToInstall();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstallModal;
