import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { detectError } from "../../utility";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import RefundsForm from "../../components/RefundsForm";
type Props = {};

const RefundView: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const [bookedBed, setBookedBed] = useState<any>(undefined);
  const [bbLoading, setBBLoading] = useState(false);
  const [refund, setRefund] = useState<any>();
  const [refundLoading, setRefundLoading] = useState(false);

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        setBBLoading(true);
        const res = await axios.get(
          `https://plab2-prod.herokuapp.com/api/bookings/${id}/`
        );
        setBBLoading(false);
        setBookedBed(res.data);
      } catch (e: any) {
        console.log(e);
        const message = detectError(e);
        toast.error(message);
        setBBLoading(false);
      }
    };

    if (id) {
      fetchBooking();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const fetchRefunds = async () => {
      try {
        setRefundLoading(true);
        const res = await axios.get(
          `https://plab2-prod.herokuapp.com/api/refunds/${id}/`
        );
        setRefundLoading(false);
        setRefund(res.data);
      } catch (e: any) {
        if (e.response.status !== 404) {
          const message = detectError(e);
          toast.error(message);
        }
        if (e.response.status === 404) {
          setRefund(undefined);
        }
        setRefundLoading(false);
      }
    };

    if (id) {
      fetchRefunds();
    }
  }, [id]);

  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center">Refund</h1>
        {(bbLoading || refundLoading) && (
          <>
            <div className="d-flex justify-content-center my-2">
              <Spinner className="spinner" animation="border" variant="dark" />
            </div>
            <p className="text-center">Loading...</p>
          </>
        )}
        {!(bbLoading || refundLoading) &&
          bookedBed &&
          bookedBed.booking.status !== "completed" && (
            <div className="d-flex justify-content-center">
              <p className="text-danger fw-bold fs-5 text-center">
                Booking status may not have been completed. Kindly wait a day
                after your booking expires. If this is not the case, kindly
                contact admin. When your booking is marked as completed, a form
                will be displayed here for you to fill in your account details
                for refund.
              </p>
            </div>
          )}
        {bookedBed && bookedBed.booking.refund_status === "refunded" && (
          <div className="d-flex justify-content-center">
            <p className="text-success fw-bold fs-5 text-center">
              Refund has been paid for this booking. If you have any complaints
              kindly contact admin.
            </p>
          </div>
        )}
        {bookedBed && bookedBed.booking.voided_status === "voided" && (
          <div className="d-flex justify-content-center">
            <p className="text-danger fw-bold fs-5 text-center">
              Booking has been voided. If you have any complaints kindly contact
              admin.
            </p>
          </div>
        )}
        {bookedBed &&
          bookedBed.booking.status === "completed" &&
          bookedBed.booking.refund_status === "not_refunded" &&
          bookedBed.booking.voided_status === "not_voided" && (
            <div className="card">
              <div className="card-body">
                <h3>Refund Details</h3>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-3">
                    <p className={"fw-bold"}>Customer Name</p>
                    <p className={""}>
                      {bookedBed?.booking?.customer_initials}{" "}
                      {bookedBed?.booking?.customer_lastname}{" "}
                      {bookedBed?.booking?.customer_firstname}
                    </p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <p className={"fw-bold"}>Email</p>
                    <p className={""}>{bookedBed?.booking?.customer_email}</p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <p className={"fw-bold"}>Phone</p>
                    <p className={""}>{bookedBed?.booking?.customer_phone}</p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <p className={"fw-bold"}>Caution Fee</p>
                    <p className={""}>{bookedBed?.booking?.caution_fee} GBP</p>
                  </div>
                </div>
                <h3 className={"mt-4 mb-4"}>
                  Provide account details for refund
                </h3>
                <RefundsForm refunds_details={refund} receipt_id={id} />
                {refund && (
                  <div>
                    <h1 className={"mt-4 mb-3"}>Refunds Status</h1>
                    <div>
                      <p>
                        Issues: {refund.issues ? refund.issues : "No Issues"}
                      </p>
                      <p>Status: {refund.status}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default RefundView;
