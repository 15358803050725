import React from "react";
import BackButton from "../../components/BackButton";

type Props = {};

const Others: React.FC<Props> = (props: Props) => {
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
      </div>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="bg-secondary p-5">
          <h1 className="text-center text-light">Coming Soon</h1>
        </div>
      </div>
    </div>
  );
};

export default Others;
