import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type HouseState = {
  houses: any[];
};

const initialState: HouseState = {
  houses: [],
};

const houseSlice = createSlice({
  name: "house",
  initialState,
  reducers: {
    setHouses: (state, action: PayloadAction<any[]>) => {
      state.houses = action.payload;
    },
  },
});

export const { setHouses } = houseSlice.actions;
export default houseSlice.reducer;
