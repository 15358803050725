import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BusState = {
  bus: null | {
    id: string;
    name: string;
    plateNumber: string;
    state: string;
    status: string;
    capacity: number;
    model?: string | null | undefined;
  };
  driver: {
    username: string;
  } | null;
  route: {
    id: string;
    name: string;
    stops: {
      name: string;
      latitude: number;
      longitude: number;
    }[];
  } | null;
  currentLocation: {
    latitude: number;
    longitude: number;
  } | null;
};

const initialState: BusState = {
  bus: null,
  driver: null,
  route: null,
  currentLocation: null,
};

const busSlice = createSlice({
  name: "bus",
  initialState,
  reducers: {
    setBus: (state, action: PayloadAction<BusState["bus"]>) => {
      state.bus = action.payload;
    },
    setDriver: (state, action: PayloadAction<BusState["driver"]>) => {
      state.driver = action.payload;
    },
    setRoute: (state, action: PayloadAction<BusState["route"]>) => {
      state.route = action.payload;
    },
    setCurrentLocation: (
      state,
      action: PayloadAction<BusState["currentLocation"]>
    ) => {
      state.currentLocation = action.payload;
    },
  },
});

export const {
  setBus,
  setDriver,
  setRoute,
  setCurrentLocation,
} = busSlice.actions;

export default busSlice.reducer;
