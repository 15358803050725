import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useGetBookingsQuery } from "../../graphql/hooks";
import BackButton from "../../components/BackButton";
import { detectError } from "../../utility";
type Props = {};

const Refund: React.FC<Props> = (props: Props) => {
  const { loading, bookings, error } = useGetBookingsQuery();
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center">Your Bookings</h1>
        <p className="text-center">
          Hint: Submit a refund request a day after your booking expires
        </p>
        {loading && (
          <>
            <div className="d-flex justify-content-center my-2">
              <Spinner className="spinner" animation="border" variant="dark" />
            </div>
            <p className="text-center">Loading Your Bookings...</p>
          </>
        )}
        {error && (
          <>
            <p className="text-center text-danger">Error Loading Bookings</p>
            <p className="text-center text-danger">{detectError(error)}</p>
          </>
        )}
        {!loading && !error && bookings && bookings.length === 0 && (
          <p className="text-center">No Available Bookings</p>
        )}

        {!loading && !error && bookings && bookings.length > 0 && (
          <>
            <div className="row g-3">
              {bookings.map((booking) => (
                <div className="col-12 col-lg-4" key={booking.id}>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">
                        {booking.initial_house_name
                          ? booking.initial_house_name
                          : booking.house_name}
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row g-1">
                        <div className="col-6">
                          <span className="badge bg-success">Room Name</span>
                          <p className="card-text">{booking.room_name}</p>
                        </div>
                        <div className="col-6">
                          <span className="badge bg-success">Room Type</span>
                          <p className="card-text">{booking.room_type}</p>
                        </div>
                        <div className="col-6">
                          <span className="badge bg-success">Bed Name</span>
                          <p className="card-text">{booking.bed_name}</p>
                        </div>
                        <div className="col-6">
                          <span className="badge bg-success">Status</span>
                          <p className="card-text">{booking.status}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <Link to={booking.id} className="btn btn-secondary w-100">
                        Submit a Refund Request
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Refund;
