import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBeforeInstallPromptEvent } from "../../../hooks/useAddToHomeScreenPrompt";

type PromptState = {
  prompt: IBeforeInstallPromptEvent | null;
};

const initialState: PromptState = {
  prompt: null,
};

const promptSlice = createSlice({
  name: "installBanner",
  initialState,
  reducers: {
    setPrompt: (state, action: PayloadAction<IBeforeInstallPromptEvent>) => {
      state.prompt = action.payload;
    },
    removePrompt: (state) => {
      state.prompt = null;
    },
  },
});

export const { setPrompt, removePrompt } = promptSlice.actions;

export default promptSlice.reducer;
