import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { apolloClient } from "./graphql/client";
import { checkAuth } from "./store/features/users/userSlice";
import { removePrompt } from "./store/features/installPrompt/promptSlice";
import { useAppDispatch } from "./store/app/hooks";
import Routes from "./AppRoutes";

function App() {
  const dispatch = useAppDispatch();
  dispatch(checkAuth());

  useEffect(() => {
    const func = () => {
      // Hide the app-provided install promotion
      // Clear the deferredPrompt so it can be garbage collected
      dispatch(removePrompt());
      // Optionally, send analytics event to indicate successful install
      console.log("PWA was installed");
    };
    window.addEventListener("appinstalled", func);

    return () => {
      window.removeEventListener("appinstalled", func);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Routes />
      <ToastContainer
        position="top-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ApolloProvider>
  );
}

export default App;
