import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../store/app/hooks";
import { useBusStopDistanceUpdatedSubscription } from "../../graphql/hooks";
import { toast } from "react-toastify";
import { detectError } from "../../utility";
import classes from "./index.module.scss";
import BackButton from "../../components/BackButton";
import { Spinner } from "react-bootstrap";

type Props = {};

type Route = {
  routeName: string;
  routeId: string;
  busStopPoints: {
    name: string;
    distance: string;
    duration: string;
    status: string;
  }[];
} | null;
const RouteDistance: React.FC<Props> = (props: Props) => {
  const { route } = useAppSelector((state) => state.busDetails);
  const activeStop = useAppSelector((state) => state.auth.activeStop);
  const [routeDetails, setRouteDetails] = useState<Route>(null);
  const {
    routeDistance,
    error,
    loading,
  } = useBusStopDistanceUpdatedSubscription(route!.id);

  useEffect(() => {
    if (routeDistance) {
      setRouteDetails(routeDistance);
    }
  }, [routeDistance]);

  if (error) {
    toast.error(detectError(error));
  }
  console.log(loading);

  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <div className="d-flex justify-content-center align-items-center">
          <div className={classes.main}>
            <h3 className="text-center mb-3">
              Distance And Duration To Your Stop
            </h3>
            {loading && (
              <div>
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner
                    className="spinner"
                    animation="border"
                    variant="dark"
                  />
                </div>
                <p className="text-center">
                  Waiting for server to send latest data...
                </p>
              </div>
            )}
            {routeDetails && (
              <div className={classes.card}>
                <h4 className="text-center">
                  Route Name: {routeDetails.routeName}
                </h4>
                <div className={classes.card_body}>
                  {routeDetails.busStopPoints.map((busStopPoint, index) => (
                    <div key={index} className={classes.card_body_list}>
                      {activeStop && activeStop === busStopPoint.name && (
                        <p
                          className="text-center fw-bold fs-6"
                          style={{ color: "green" }}
                        >
                          You are at this stop
                        </p>
                      )}
                      <p>Bus Stop Name: {busStopPoint.name}</p>
                      <p>
                        Driver Distance To this Stop:{" "}
                        {isNaN(+busStopPoint.distance)
                          ? busStopPoint.distance
                          : `${Math.round(+busStopPoint.distance)} meters`}
                      </p>
                      <p>
                        Time to this stop:{" "}
                        {isNaN(+busStopPoint.duration)
                          ? busStopPoint.duration
                          : `${Math.round(
                              +busStopPoint.duration / 60
                            )} minutes`}
                      </p>
                      <p>
                        BusStop Visited:{" "}
                        {busStopPoint.status === "pending" ? "No" : "Yes"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteDistance;
