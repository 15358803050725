import React, { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { detectError } from "../../utility";

type Props = {
  refunds_details: {
    means_of_payment: string;
    bank_name: string;
    account_number: string;
    account_name: string;
    sort_code: string;
    status: string;
  };
  receipt_id: string | undefined;
};

const validationSchema = Yup.object({
  means_of_payment: Yup.string().required("Required"),
  bank_name: Yup.string().required("Required"),
  account_number: Yup.string().required("Required"),
  account_name: Yup.string().required("Required"),
  sort_code: Yup.string().required("Required"),
});

const RefundsForm: React.FC<Props> = (props: Props) => {
  const mop = props.refunds_details?.means_of_payment
    ? props.refunds_details.means_of_payment
    : "";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState(mop);
  const showButton = props.refunds_details?.status
    ? !(
        props.refunds_details.status === "completed" ||
        props.refunds_details.status === "cancelled"
      )
    : true;

  const initialValues = {
    means_of_payment: props.refunds_details?.means_of_payment
      ? props.refunds_details.means_of_payment
      : "",
    bank_name: props.refunds_details?.bank_name
      ? props.refunds_details.bank_name
      : "",
    account_number: props.refunds_details?.account_number
      ? props.refunds_details.account_number
      : "",
    account_name: props.refunds_details?.account_name
      ? props.refunds_details.account_name
      : "",
    sort_code: props.refunds_details?.sort_code
      ? props.refunds_details.sort_code
      : "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values) => {
        try {
          setLoading(true);
          if (props.refunds_details) {
            const data = { ...values };
            await axios.patch(
              `https://plab2-prod.herokuapp.com/api/refunds/${props.receipt_id}/`,
              data
            );
          } else {
            const data = { ...values, booking: props.receipt_id };
            await axios.post(
              `https://plab2-prod.herokuapp.com/api/refunds/`,
              data
            );
          }
          toast.success("Submitted Successfully");
          navigate("/refunds");
        } catch (e: any) {
          const message = detectError(e);
          toast.error(message);
          setLoading(false);
        }
      }}
    >
      {(formik) => {
        return (
          <FormikForm className={"mb-5"}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="p-0">Payment Options</label>
                <Field
                  as="select"
                  className={`form-select ${
                    formik.errors.means_of_payment &&
                    formik.touched.means_of_payment
                      ? "is-invalid"
                      : ""
                  }`}
                  name="means_of_payment"
                  onChange={(e: any) => {
                    setSelectedBank(e.target.value);
                    formik.handleChange(e);
                  }}
                >
                  <option value="">Payment Options</option>
                  <option value="nigerian-bank">Nigerian Bank</option>
                  <option value="uk-bank">UK Bank</option>
                </Field>
                <ErrorMessage
                  component="div"
                  className="text-danger p-0"
                  name="means_of_payment"
                />
              </div>
              <div className="col-md-6">
                <label className="p-0">
                  Bank Name{" "}
                  {selectedBank === "nigerian-bank" ? (
                    <span className="text-danger">
                      (For Nigerian banks, please use a naira account not
                      domiciliary)
                    </span>
                  ) : null}
                </label>
                <Field
                  type="text"
                  className={`form-control ${
                    formik.errors.bank_name && formik.touched.bank_name
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Bank Name"
                  name="bank_name"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger p-0"
                  name="bank_name"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="p-0">
                  Account Number
                  {selectedBank === "nigerian-bank" ? (
                    <span className="text-danger">
                      (For Nigerian banks, please use a naira account not
                      domiciliary)
                    </span>
                  ) : null}
                </label>
                <Field
                  type="text"
                  className={`form-control ${
                    formik.errors.account_number &&
                    formik.touched.account_number
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Account Number"
                  name="account_number"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger p-0"
                  name="account_number"
                />
              </div>
              <div className="col-md-6">
                <label className="p-0">Account Name</label>
                <Field
                  type="text"
                  className={`form-control ${
                    formik.errors.account_name && formik.touched.account_name
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Account Name"
                  name="account_name"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger p-0"
                  name="account_name"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="p-0">
                  Sort Code
                  {selectedBank === "nigerian-bank" ? (
                    <span className="text-danger">
                      (For Nigerian banks, if you don't know your sort code, use
                      000000)
                    </span>
                  ) : null}
                </label>
                <Field
                  type="text"
                  className={`form-control ${
                    formik.errors.sort_code && formik.touched.sort_code
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter Sort Code"
                  name="sort_code"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger p-0"
                  name="sort_code"
                />
              </div>
            </div>
            <div className="row">
              {showButton && (
                <div className="col-12 col-md-6 col-lg-4">
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <>
                      <p className={"fw-bold text-danger"}>
                        Note: refunds are paid out once every week
                      </p>
                      <Button
                        disabled={!formik.isValid}
                        className="w-100"
                        variant="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default RefundsForm;
