import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient as createWsClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { Kind, OperationTypeNode } from "graphql";
import { getAccessToken, getRefreshToken, setAccessToken } from "../auths";
import axios from "../client-axios";

function isSubscription(operation: any) {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === Kind.OPERATION_DEFINITION &&
    definition.operation === OperationTypeNode.SUBSCRIPTION
  );
}

const refreshLink = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: "accessToken",
    isTokenValidOrUndefined: () => {
      // false - token is not valid
      // true - token is valid or there is no token
      return new Promise((resolve, reject) => {
        const token = getAccessToken();
        if (!token) {
          resolve(false);
        } else {
          try {
            const { exp } = jwtDecode(token) as any;
            if (Date.now() >= exp * 1000) {
              resolve(false);
            } else {
              resolve(true);
            }
          } catch (err) {
            resolve(false);
          }
        }
      });
    },
    fetchAccessToken: () => {
      return axios.post(
        "/refresh-token",
        {},
        { headers: { Authorization: `Bearer ${getRefreshToken()}` } }
      );
    },
    handleFetch: (accessToken) => {
      setAccessToken(accessToken);
    },
    handleResponse: (operation, accessTokenField) => (response: any) => {
      return {
        accessToken: response.data[accessTokenField],
        ok: response.data.ok,
      };
    },
    handleError: (err) => {
      // full control over handling token fetch Error
      // console.log(err);
      if (getRefreshToken()) {
        alert(
          "Your session may have expired or you don't have internet connection. Please check your internet connection or login again."
        );
        console.warn("Your refresh token is invalid. Try to relogin");
      }
    },
  }),
]);

const uri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7000/graphql"
    : "https://plab-mgt-app.herokuapp.com/graphql";

const httpLink = createHttpLink({ uri });

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    operation.setContext({
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  return forward(operation);
});

const url =
  process.env.NODE_ENV === "development"
    ? "ws://localhost:7000/graphql"
    : "wss://plab-mgt-app.herokuapp.com/graphql";

const wsLink = new GraphQLWsLink(
  createWsClient({
    url,
    connectionParams: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
  })
);

export const apolloClient = new ApolloClient({
  link: refreshLink.concat(
    split(isSubscription, wsLink, concat(authLink, httpLink))
  ),
  cache: new InMemoryCache(),
});

//https://buildpack-registry.s3.amazonaws.com/buildpacks/mars/create-react-app.tgz
