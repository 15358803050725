/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation SignUpDriver($input: CreatePassengerInput!) {\n    signUpPassenger(input: $input) {\n      id\n    }\n  }\n": types.SignUpDriverDocument,
    "\n  query GetMe {\n    getMe {\n      id\n      username\n      email\n      phone\n      role\n    }\n  }\n": types.GetMeDocument,
    "\n  query GetAllBuses {\n    getAllBuses {\n      id\n      name\n      plateNumber\n      state\n      status\n      capacity\n      model\n      driver {\n        id\n        username\n      }\n    }\n  }\n": types.GetAllBusesDocument,
    "\n  query GetAllRoutes {\n    getAllRoutes {\n      id\n      name\n      stops {\n        id\n        name\n      }\n    }\n  }\n": types.GetAllRoutesDocument,
    "\n  query GetAllBusStops {\n    getAllBusStops {\n      id\n      name\n      address\n    }\n  }\n": types.GetAllBusStopsDocument,
    "\n  query GetPassenger($getPassengerId: ID!) {\n    getPassenger(id: $getPassengerId) {\n      id\n      username\n      booking {\n        id\n        house_name\n        status\n        check_in_date\n        check_out_date\n      }\n    }\n  }\n": types.GetPassengerDocument,
    "\n  query GetPassengerBus($stopName: String!) {\n    getPassengerBus(stopName: $stopName) {\n      id\n      name\n      capacity\n      plateNumber\n      state\n      status\n      model\n      driver {\n        username\n      }\n      currentLocation {\n        id\n        latitude\n        longitude\n      }\n      route {\n        id\n        name\n        stops {\n          name\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n": types.GetPassengerBusDocument,
    "\n  subscription Subscription($busId: ID!) {\n    busLocationUpdated(busId: $busId) {\n      latitude\n      longitude\n    }\n  }\n": types.SubscriptionDocument,
    "\n  subscription BusStopDistanceUpdated($routeId: ID!) {\n    busStopDistanceUpdated(routeId: $routeId) {\n      routeId\n      routeName\n      busStopPoints {\n        name\n        distance\n        duration\n        status\n      }\n    }\n  }\n": types.BusStopDistanceUpdatedDocument,
    "\n  query GetBookings {\n    getBookings {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      initial_house_name\n      house_id\n      status\n    }\n  }\n": types.GetBookingsDocument,
    "\n  query GetBooking($getBookingId: ID!) {\n    getBooking(id: $getBookingId) {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      initial_house_name\n      house_id\n      customer_initials\n      customer_firstname\n      customer_lastname\n      customer_phone\n      customer_email\n      customer_address\n      customer_country\n      gender_type\n      status\n      bed_price\n      total_amount\n      caution_fee\n      check_in_date\n      check_out_date\n      exam_date\n    }\n  }\n": types.GetBookingDocument,
    "\n  mutation AddBooking($bookingId: String!) {\n    addBooking(bookingId: $bookingId) {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      house_id\n      status\n    }\n  }\n": types.AddBookingDocument,
    "\n  query GetUserTaxiBookings {\n    getUserTaxiBookings {\n      id\n      fullName\n      pickUpLocation\n      pickUpTime\n      status\n      bookingType\n      destination\n      examDate\n    }\n  }\n": types.GetUserTaxiBookingsDocument,
    "\n  mutation CreateTaxiBooking(\n    $fullName: String!\n    $phoneNumber: String!\n    $pickUpLocation: String!\n    $destination: String!\n    $pickUpTime: String!\n    $examDate: String!\n    $bookingId: String!\n  ) {\n    createTaxiBooking(\n      fullName: $fullName\n      phoneNumber: $phoneNumber\n      pickUpLocation: $pickUpLocation\n      destination: $destination\n      pickUpTime: $pickUpTime\n      examDate: $examDate\n      bookingId: $bookingId\n    ) {\n      id\n    }\n  }\n": types.CreateTaxiBookingDocument,
    "\n  mutation CreateUserSubscription(\n    $endpoint: String!\n    $p256Dh: String!\n    $authKey: String!\n  ) {\n    createUserSubscription(\n      endpoint: $endpoint\n      p256dh: $p256Dh\n      authKey: $authKey\n    )\n  }\n": types.CreateUserSubscriptionDocument,
    "\n  mutation ChangePassengerStop($bookingId: String!, $newStop: String!) {\n    changePassengerStop(bookingId: $bookingId, newStop: $newStop) {\n      id\n      house_name\n      status\n      check_in_date\n      check_out_date\n    }\n  }\n": types.ChangePassengerStopDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SignUpDriver($input: CreatePassengerInput!) {\n    signUpPassenger(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation SignUpDriver($input: CreatePassengerInput!) {\n    signUpPassenger(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMe {\n    getMe {\n      id\n      username\n      email\n      phone\n      role\n    }\n  }\n"): (typeof documents)["\n  query GetMe {\n    getMe {\n      id\n      username\n      email\n      phone\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllBuses {\n    getAllBuses {\n      id\n      name\n      plateNumber\n      state\n      status\n      capacity\n      model\n      driver {\n        id\n        username\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllBuses {\n    getAllBuses {\n      id\n      name\n      plateNumber\n      state\n      status\n      capacity\n      model\n      driver {\n        id\n        username\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllRoutes {\n    getAllRoutes {\n      id\n      name\n      stops {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllRoutes {\n    getAllRoutes {\n      id\n      name\n      stops {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllBusStops {\n    getAllBusStops {\n      id\n      name\n      address\n    }\n  }\n"): (typeof documents)["\n  query GetAllBusStops {\n    getAllBusStops {\n      id\n      name\n      address\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPassenger($getPassengerId: ID!) {\n    getPassenger(id: $getPassengerId) {\n      id\n      username\n      booking {\n        id\n        house_name\n        status\n        check_in_date\n        check_out_date\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPassenger($getPassengerId: ID!) {\n    getPassenger(id: $getPassengerId) {\n      id\n      username\n      booking {\n        id\n        house_name\n        status\n        check_in_date\n        check_out_date\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPassengerBus($stopName: String!) {\n    getPassengerBus(stopName: $stopName) {\n      id\n      name\n      capacity\n      plateNumber\n      state\n      status\n      model\n      driver {\n        username\n      }\n      currentLocation {\n        id\n        latitude\n        longitude\n      }\n      route {\n        id\n        name\n        stops {\n          name\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPassengerBus($stopName: String!) {\n    getPassengerBus(stopName: $stopName) {\n      id\n      name\n      capacity\n      plateNumber\n      state\n      status\n      model\n      driver {\n        username\n      }\n      currentLocation {\n        id\n        latitude\n        longitude\n      }\n      route {\n        id\n        name\n        stops {\n          name\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription Subscription($busId: ID!) {\n    busLocationUpdated(busId: $busId) {\n      latitude\n      longitude\n    }\n  }\n"): (typeof documents)["\n  subscription Subscription($busId: ID!) {\n    busLocationUpdated(busId: $busId) {\n      latitude\n      longitude\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription BusStopDistanceUpdated($routeId: ID!) {\n    busStopDistanceUpdated(routeId: $routeId) {\n      routeId\n      routeName\n      busStopPoints {\n        name\n        distance\n        duration\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription BusStopDistanceUpdated($routeId: ID!) {\n    busStopDistanceUpdated(routeId: $routeId) {\n      routeId\n      routeName\n      busStopPoints {\n        name\n        distance\n        duration\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBookings {\n    getBookings {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      initial_house_name\n      house_id\n      status\n    }\n  }\n"): (typeof documents)["\n  query GetBookings {\n    getBookings {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      initial_house_name\n      house_id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBooking($getBookingId: ID!) {\n    getBooking(id: $getBookingId) {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      initial_house_name\n      house_id\n      customer_initials\n      customer_firstname\n      customer_lastname\n      customer_phone\n      customer_email\n      customer_address\n      customer_country\n      gender_type\n      status\n      bed_price\n      total_amount\n      caution_fee\n      check_in_date\n      check_out_date\n      exam_date\n    }\n  }\n"): (typeof documents)["\n  query GetBooking($getBookingId: ID!) {\n    getBooking(id: $getBookingId) {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      initial_house_name\n      house_id\n      customer_initials\n      customer_firstname\n      customer_lastname\n      customer_phone\n      customer_email\n      customer_address\n      customer_country\n      gender_type\n      status\n      bed_price\n      total_amount\n      caution_fee\n      check_in_date\n      check_out_date\n      exam_date\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddBooking($bookingId: String!) {\n    addBooking(bookingId: $bookingId) {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      house_id\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation AddBooking($bookingId: String!) {\n    addBooking(bookingId: $bookingId) {\n      id\n      bed_name\n      room_name\n      room_type\n      house_name\n      house_id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUserTaxiBookings {\n    getUserTaxiBookings {\n      id\n      fullName\n      pickUpLocation\n      pickUpTime\n      status\n      bookingType\n      destination\n      examDate\n    }\n  }\n"): (typeof documents)["\n  query GetUserTaxiBookings {\n    getUserTaxiBookings {\n      id\n      fullName\n      pickUpLocation\n      pickUpTime\n      status\n      bookingType\n      destination\n      examDate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTaxiBooking(\n    $fullName: String!\n    $phoneNumber: String!\n    $pickUpLocation: String!\n    $destination: String!\n    $pickUpTime: String!\n    $examDate: String!\n    $bookingId: String!\n  ) {\n    createTaxiBooking(\n      fullName: $fullName\n      phoneNumber: $phoneNumber\n      pickUpLocation: $pickUpLocation\n      destination: $destination\n      pickUpTime: $pickUpTime\n      examDate: $examDate\n      bookingId: $bookingId\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTaxiBooking(\n    $fullName: String!\n    $phoneNumber: String!\n    $pickUpLocation: String!\n    $destination: String!\n    $pickUpTime: String!\n    $examDate: String!\n    $bookingId: String!\n  ) {\n    createTaxiBooking(\n      fullName: $fullName\n      phoneNumber: $phoneNumber\n      pickUpLocation: $pickUpLocation\n      destination: $destination\n      pickUpTime: $pickUpTime\n      examDate: $examDate\n      bookingId: $bookingId\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUserSubscription(\n    $endpoint: String!\n    $p256Dh: String!\n    $authKey: String!\n  ) {\n    createUserSubscription(\n      endpoint: $endpoint\n      p256dh: $p256Dh\n      authKey: $authKey\n    )\n  }\n"): (typeof documents)["\n  mutation CreateUserSubscription(\n    $endpoint: String!\n    $p256Dh: String!\n    $authKey: String!\n  ) {\n    createUserSubscription(\n      endpoint: $endpoint\n      p256dh: $p256Dh\n      authKey: $authKey\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ChangePassengerStop($bookingId: String!, $newStop: String!) {\n    changePassengerStop(bookingId: $bookingId, newStop: $newStop) {\n      id\n      house_name\n      status\n      check_in_date\n      check_out_date\n    }\n  }\n"): (typeof documents)["\n  mutation ChangePassengerStop($bookingId: String!, $newStop: String!) {\n    changePassengerStop(bookingId: $bookingId, newStop: $newStop) {\n      id\n      house_name\n      status\n      check_in_date\n      check_out_date\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;