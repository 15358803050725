import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useSignUpMutation } from "../../graphql/hooks";
import logo from "../../images/logos/plab_logo.png";
import classes from "./index.module.scss";
import { detectError } from "../../utility";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import InstallModal from "../../components/InstallModal";
import { useAppSelector } from "../../store/app/hooks";

type Props = {};

const SignUpSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  booking: Yup.string().uuid("Invalid booking id").required("Required"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Required"),
});

const SignUp: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { prompt } = useAppSelector((state) => state.installPrompt);
  const { signUp, loading } = useSignUpMutation();
  // console.dir(error);
  return (
    <div className={classes.signup}>
      <div className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-sm-10 col-md-8 col-lg-5">
            <div className={`card ${classes.signup_card}`}>
              <div className="card-body">
                <img
                  src={logo}
                  alt="Company Logo"
                  className="img-fluid mb-4 mx-auto d-block"
                />
                <Formik
                  initialValues={{
                    username: "",
                    booking: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={SignUpSchema}
                  onSubmit={async (values) => {
                    try {
                      localStorage.removeItem("userData");
                      await signUp({
                        variables: {
                          input: {
                            username: values.username,
                            booking: values.booking,
                            password: values.password,
                          },
                        },
                      });
                      toast.success(
                        "Sign up successful. Please login with your email and password to continue (Use the email you used to make the booking)."
                      );
                      navigate("/login");
                    } catch (err: any) {
                      const res = detectError(err);
                      toast.error(res);
                    }
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label
                          htmlFor="username"
                          className="fw-bold fs-5 text-white"
                        >
                          Username
                        </label>
                        <Field
                          name="username"
                          id="username"
                          placeholder="Enter username"
                          className={`form-control ${
                            errors.username && touched.username
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="username"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="booking"
                          className="fw-bold fs-5 text-white"
                        >
                          Receipt ID
                        </label>
                        <Field
                          name="booking"
                          id="booking"
                          placeholder="Enter Receipt id"
                          className={`form-control ${
                            errors.booking && touched.booking
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="booking"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="password"
                          className="fw-bold fs-5 text-white"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          placeholder="Enter password"
                          id="password"
                          className={`form-control ${
                            errors.password && touched.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="confirmPassword"
                          className="fw-bold fs-5 text-white"
                        >
                          Confirm Password
                        </label>
                        <Field
                          name="confirmPassword"
                          type="password"
                          placeholder="Repeat password"
                          id="confirmPassword"
                          className={`form-control ${
                            errors.confirmPassword && touched.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="confirmPassword"
                          className="invalid-feedback"
                        />
                      </div>
                      {loading ? (
                        <div className="d-flex justify-content-center mt-3">
                          <Spinner
                            className="spinner"
                            animation="border"
                            variant="light"
                          />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block w-100 mt-3"
                          disabled={loading}
                        >
                          Sign Up
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
                <div className="d-flex justify-content-center mt-3">
                  <Link className="text-bg-warning" to="/login">
                    Already have an account? Log in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {prompt && <InstallModal />}
    </div>
  );
};

export default SignUp;
