import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../client-axios";
import logo from "../../images/logos/plab_logo.png";
import classes from "./index.module.scss";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const ResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Required"),
});

type TokenParam = {
  token: string;
};

type Props = {};

const ResetPassword: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const params = useParams<TokenParam>();
  const navigate = useNavigate();
  return (
    <div className={classes.reset}>
      <div className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-sm-10 col-md-8 col-lg-5">
            <div className={`card ${classes.reset_card}`}>
              <div className="card-body">
                <img
                  src={logo}
                  alt="Company Logo"
                  className="img-fluid mb-4 mx-auto d-block"
                />
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={ResetSchema}
                  onSubmit={async (values) => {
                    // do form submission here
                    // console.log(params);
                    // console.log(values);
                    try {
                      if (params.token) {
                        if (values.password !== values.confirmPassword) {
                          toast.error("Passwords do not match");
                          return;
                        }
                        setLoading(true);
                        await axios.post("/resetpassword", {
                          token: params.token,
                          password: values.password,
                        });
                        setLoading(false);
                        toast.success("Password reset successful");
                        navigate("/login");
                      } else {
                        toast.error("Invalid token, Please use link in email");
                      }
                    } catch (error: any) {
                      toast.error(error.response.data.error);
                      setLoading(false);
                    }
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label
                          htmlFor="password"
                          className="fw-bold fs-5 text-white"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          id="password"
                          className={`form-control ${
                            errors.password && touched.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="confirmPassword"
                          className="fw-bold fs-5 text-white"
                        >
                          Confirm Password
                        </label>
                        <Field
                          name="confirmPassword"
                          type="password"
                          id="confirmPassword"
                          className={`form-control ${
                            errors.confirmPassword && touched.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="confirmPassword"
                          className="invalid-feedback"
                        />
                      </div>
                      {loading ? (
                        <div className="d-flex justify-content-center mt-3 w-100">
                          <Spinner
                            className="spinner"
                            animation="border"
                            variant="light"
                          />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block w-100 mt-3"
                        >
                          Reset Password
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
