import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../dataTypes";

type UserState = {
  user: User | null;
  activeStop: string;
  status: string;
  bookingDays: number;
  bookingId: string;
};

const initialState: UserState = {
  user: null,
  activeStop: "",
  status: "",
  bookingDays: 0,
  bookingId: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      let date = new Date();
      const expiresIn = date.setDate(date.getDate() + 7);
      const expires = new Date(expiresIn);
      const now = new Date();

      const expMilliSeconds = expires.getTime() - now.getTime();
      console.log("Creating timeout");
      setTimeout(() => {
        state.user = null;
        localStorage.removeItem("user");
      }, expMilliSeconds);
      const user = { ...action.payload, expiresIn };
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
    checkAuth: (state) => {
      let authuser = localStorage.getItem("user");
      if (!authuser) {
        state.user = null;
      } else {
        const tempUser = JSON.parse(authuser);
        const { expiresIn } = tempUser;
        if (expiresIn < Date.now()) {
          localStorage.removeItem("user");
          state.user = null;
        } else {
          const expires = new Date(expiresIn);
          const now = new Date();
          const expMilliSeconds = expires.getTime() - now.getTime();
          setTimeout(() => {
            state.user = null;
            localStorage.removeItem("user");
          }, expMilliSeconds);

          state.user = tempUser;
        }
      }
    },
    setActiveStop: (state, action: PayloadAction<string>) => {
      state.activeStop = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setBookingDays: (state, action: PayloadAction<number>) => {
      state.bookingDays = action.payload;
    },
    setBookingId: (state, action: PayloadAction<string>) => {
      state.bookingId = action.payload;
    },
  },
});

export const {
  login,
  logout,
  checkAuth,
  setActiveStop,
  setStatus,
  setBookingDays,
  setBookingId,
} = userSlice.actions;
export default userSlice.reducer;
