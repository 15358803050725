import React from "react";
import Container from "react-bootstrap/Container";
import classes from "./index.module.scss";
import { useAppSelector } from "../../store/app/hooks";
import PassengerDetails from "../PassengerDetails";

type Props = {
  // mainTitle: string;
  loading: boolean;
};

const MiniBanner: React.FC<Props> = ({ loading }) => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <div className={classes.mini_banner_background}>
      <Container className="h-100">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className={classes.mini_banner_background_content}>
            <h1 className={"fw-bold text-center mb-4"}>
              Hello {user?.username}!
            </h1>
            <PassengerDetails loadingData={loading} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MiniBanner;
