import React, { useEffect } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
type Props = {
  extraClasses?: string;
};

const BackButton: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      className={`bg-transparent d-flex justify-content-start align-items-center my-3 ${props.extraClasses}`}
    >
      <button
        className="btn ps-0"
        onClick={() => navigate(-1)}
        disabled={location.key === "default"}
      >
        <HiArrowNarrowLeft fontSize={35} />
      </button>
    </div>
  );
};

export default BackButton;
