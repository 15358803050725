import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as CarIcon } from "../../images/svgs/car-nav.svg";
import { ReactComponent as CarIcon } from "../../images/svgs/school_bus.svg";
import { ReactComponent as BookingIcon } from "../../images/svgs/booking.svg";
import { ReactComponent as HouseIcon } from "../../images/svgs/house.svg";
import { ReactComponent as RefundIcon } from "../../images/svgs/refund.svg";
import { ReactComponent as TaxiIcon } from "../../images/svgs/taxi.svg";
import { ReactComponent as MiscIcon } from "../../images/svgs/misc.svg";
import { useAppSelector, useAppDispatch } from "../../store/app/hooks";
import MiniBanner from "../MiniBanner";
import classes from "./index.module.scss";
import { useGetPassengerDetailsQuery } from "../../graphql/hooks";
import {
  setActiveStop,
  setStatus,
  setBookingDays,
  setBookingId,
} from "../../store/features/users/userSlice";
import { Spinner } from "react-bootstrap";

type Props = {};

const MainBoard: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const {
    activeStop,
    loading,
    status,
    bookingDays,
    bookingId,
  } = useGetPassengerDetailsQuery(user!.id);

  useEffect(() => {
    if (activeStop) {
      dispatch(setActiveStop(activeStop));
      dispatch(setStatus(status));
      dispatch(setBookingDays(bookingDays));
      dispatch(setBookingId(bookingId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStop, status, bookingDays]);

  return (
    <div className={`${classes.mainboard} w-100 h-100`}>
      <MiniBanner loading={loading} />
      {/*<PassengerDetails*/}
      {/*  status={status}*/}
      {/*  bookingDays={bookingDays}*/}
      {/*  activeStop={activeStop}*/}
      {/*/>*/}
      <div className="container">
        <div className="row g-5 p-3">
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex justify-content-center align-items-center h-100">
              {loading ? (
                <div
                  className={`${classes.mainboard_card} card d-flex justify-content-center align-items-center`}
                >
                  <Spinner
                    className="spinner"
                    animation="border"
                    variant="dark"
                  />
                </div>
              ) : (
                <Link to="tracking">
                  <div className={`${classes.mainboard_card} card`}>
                    <div className="card-body">
                      <CarIcon className="img-fluid mb-4 mx-auto d-block" />
                      <h5 className="card-title text-center fw-bold text-bg-warning">
                        School Bus Tracking
                      </h5>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to="bookings">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <BookingIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      Your Bookings
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to="houses">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <HouseIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      Your Houses
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to="refunds">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <RefundIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      Request A Refund
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to="/booktaxi">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <TaxiIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      Book A Taxi
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to="/others">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <MiscIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      Others
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBoard;
