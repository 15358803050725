import React from "react";
import BackButton from "../BackButton";
import { Link } from "react-router-dom";
import { ReactComponent as MapsIcon } from "../../images/svgs/maps-app.svg";
import { ReactComponent as RouteIcon } from "../../images/svgs/routes.svg";
import classes from "../Mainboard/index.module.scss";

type Props = {};

const BusTracking: React.FC<Props> = (props: Props) => {
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <div className="row g-3 p-3">
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex  justify-content-center align-items-center">
              <Link to="details">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <MapsIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      View Bus on Map
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex  justify-content-center align-items-center">
              <Link to="route">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <RouteIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      View Your Route
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusTracking;
