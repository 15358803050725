import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "../../client-axios";
import classes from "./index.module.scss";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
type Props = {};

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  return (
    <div className={classes.forgot}>
      <div className="container">
        {reset && (
          <div className="row justify-content-center align-items-center min-vh-100">
            <div className="col-12 col-sm-10 col-md-8 col-lg-5">
              <div className={`card`}>
                <div className="card-body">
                  <p className="fs-5">
                    Password reset was successful! Please check your email for
                    the password reset link. Please click on this link and
                    change your password.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {!reset && (
          <div className="row justify-content-center align-items-center min-vh-100">
            <div className="col-12 col-sm-10 col-md-8 col-lg-5">
              <div className={`card ${classes.forgot_card}`}>
                <div className="card-body">
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={ForgotSchema}
                    onSubmit={async (values) => {
                      // do form submission here
                      console.log(values);
                      try {
                        setLoading(true);
                        await axios.post("/forgotpassword", values);
                        setLoading(false);
                        setReset(true);
                      } catch (error: any) {
                        setLoading(false);
                        toast.error(error.response.data.error);
                      }
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="fw-bold fs-5 text-white"
                          >
                            Email
                          </label>
                          <Field
                            name="email"
                            type="email"
                            id="email"
                            aria-label="email"
                            className={`form-control ${
                              errors.email && touched.email ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback"
                          />
                        </div>
                        {loading ? (
                          <div className="d-flex justify-content-center mt-3">
                            <Spinner
                              className="spinner"
                              animation="border"
                              variant="light"
                            />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary btn-block w-100 mt-3"
                          >
                            Submit
                          </button>
                        )}
                      </Form>
                    )}
                  </Formik>
                  <div className="d-flex justify-content-center mt-3">
                    <Link to="/login">Go back to Log in</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
