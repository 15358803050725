import React from "react";
import BackButton from "../BackButton";
import { Spinner } from "react-bootstrap";
import { detectError } from "../../utility";

type Props = {
  loading: boolean;
  error: any;
  buses: any[] | null | undefined;
  activeStop: string;
  chooseFunction: (id: string) => void;
};

const BusDetail: React.FC<Props> = ({
  loading,
  error,
  buses,
  activeStop,
  chooseFunction,
}) => {
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center mb-4">Car, Driver and Route Details</h1>
        <p className="text-center">
          Please note that the free buses operate between 8am and 10am, Monday
          to Friday.
        </p>
        {loading && (
          <div className="">
            <div className="d-flex justify-content-center align-items-center">
              <Spinner className="spinner" animation="border" variant="dark" />
            </div>
            <p className="text-center">
              Fetching bus, driver and route details...
            </p>
          </div>
        )}
        {!loading && error && (
          <div>
            <div>
              <p className="text-center">
                Failed to fetch bus, driver and route details
              </p>
              <p className="text-center">{detectError(error)}</p>
            </div>
          </div>
        )}
        {buses && buses.length === 0 && (
          <div>
            <p className="text-center fw-bold fs-5">
              No bus/car is currently driving through your stop: {activeStop}
            </p>
          </div>
        )}
        {!loading && buses && buses.length > 0 && (
          <div className="row g-3">
            {buses.map((bus, index) => {
              return (
                <div key={bus?.id || index}>
                  {bus && (
                    <div className="col-12 col-sm-6 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title text-center">{bus.name}</h5>
                        </div>
                        <div className="card-body">
                          {/*<h5 className="card-title text-center">{bus.name}</h5>*/}
                          <p className="card-text m-1">Model: {bus.model}</p>
                          <p className="card-text m-1">
                            Plate Number: {bus.plateNumber}
                          </p>
                          <p className="card-text m-1">
                            Capacity: {bus.capacity}
                          </p>
                          <p className="card-text m-1">State: {bus.state}</p>
                          <p className="card-text m-1">
                            Driver: {bus?.driver?.username}
                          </p>
                          <p className="card-text m-1">
                            Route: {bus?.route?.name}
                          </p>
                          <p className="card-text m-1">Stops:</p>
                          <ul>
                            {bus?.route?.stops?.map((stop, index) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    fontWeight:
                                      activeStop === stop.name ? "bold" : "",
                                  }}
                                >
                                  {stop.name}{" "}
                                  {activeStop === stop.name && (
                                    <span style={{ color: "green" }}>
                                      {"-"}
                                      (You are here)
                                    </span>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="card-footer">
                          <button
                            type="button"
                            className="btn btn-secondary w-100"
                            onClick={() => chooseFunction(bus.id)}
                          >
                            View Bus
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BusDetail;
