import React from "react";
import * as Yup from "yup";
import BackButton from "../../components/BackButton";
import axios from "axios";
import { toast } from "react-toastify";
import useSWR from "swr";
import { useParams, useNavigate } from "react-router-dom";
import { detectError } from "../../utility";
import dateFormat from "dateformat";
import { Form as FormikForm } from "formik";
import { ErrorMessage, Field, Formik } from "formik";
import Spinner from "react-bootstrap/Spinner";
import classes from "./index.module.scss";
import { useCreateTaxiBookingMutation } from "../../graphql/hooks";

type Props = {};

const validationSchema = Yup.object({
  fullName: Yup.string().required("Required"),
  pickUpLocation: Yup.string().required("Required"),
  pickUpTime: Yup.string().required("Required"),
  examDate: Yup.string().required("Required"),
  destination: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .matches(
      /^44[0-9]{0,2}[1-9][0-9]{7,12}$/,
      "Phone number must be UK number in international format"
    )
    .required("Required"),
});

const fetcher = (url) => axios.get(url).then((res) => res.data);
const ExamTaxiForm: React.FC<Props> = (props: Props) => {
  const booking = useParams().id;
  const navigate = useNavigate();
  const { createTaxiBooking, loading } = useCreateTaxiBookingMutation();
  // const [loadingHouses, setLoadingHouses] = useState(false);
  // const [houses, setHouses] = useState([]);
  let date = new Date();
  date.setDate(date.getDate() + 6);

  const initialValues = {
    fullName: "",
    pickUpLocation: "",
    pickUpTime: "",
    examDate: "",
    destination: "",
    phoneNumber: "",
  };

  const { data: houses, isLoading: loadingHouses } = useSWR(
    "https://plab2-prod.herokuapp.com/api/houselist",
    fetcher
  );

  // useEffect(() => {
  //   const fetchHouses = async () => {
  //     try {
  //       setLoadingHouses(true);
  //       const response = await axios.get(
  //         "https://plab2-prod.herokuapp.com/api/houselist"
  //       );
  //       setHouses(response.data);
  //       setLoadingHouses(false);
  //     } catch (e) {
  //       console.log(e);
  //       setLoadingHouses(false);
  //     }
  //   };
  //
  //   fetchHouses();
  // }, []);

  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center">Enter Your Details</h1>
        <div className={` d-flex justify-content-center`}>
          <div className="card w-100">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={async (values) => {
                  try {
                    await createTaxiBooking({
                      variables: {
                        ...values,
                        bookingId: booking!,
                      },
                    });
                    toast.success(
                      "Booking Successful. Await confirmation message on thursdays"
                    );
                    navigate("/booktaxi", { replace: true });
                  } catch (e: any) {
                    const message = detectError(e);
                    toast.error(message);
                  }
                }}
              >
                {(formik) => {
                  return (
                    <FormikForm className={"mb-5"}>
                      <div className="row mb-3">
                        <div className="col-md-6 mb-2 mb-md-0">
                          <label htmlFor="fullName" className="p-0">
                            Full Name
                          </label>
                          <Field
                            className={`form-control ${
                              formik.errors.fullName && formik.touched.fullName
                                ? "is-invalid"
                                : ""
                            }`}
                            name="fullName"
                            id="fullName"
                            placeholder="Enter Full Name"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger p-0"
                            name="fullName"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="phoneNumber" className="p-0">
                            UK Phone Number International Format (e.g 44...)
                            (Don't add + sign) (Taxi driver will use it to call
                            you on the day)
                          </label>
                          <Field
                            className={`form-control ${
                              formik.errors.phoneNumber &&
                              formik.touched.phoneNumber
                                ? "is-invalid"
                                : ""
                            }`}
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Enter Phone Number"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger p-0"
                            name="phoneNumber"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6 mb-2 mb-md-0">
                          <label htmlFor="examDate" className="p-0">
                            Exam Date (Has to be at least 7 days from today)
                          </label>
                          <Field
                            className={`form-control ${
                              formik.errors.examDate && formik.touched.examDate
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Choose Exam Date"
                            min={dateFormat(date, "yyyy-mm-dd")}
                            name="examDate"
                            id="examDate"
                            type="date"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger p-0"
                            name="examDate"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="pickUpTime" className="p-0">
                            Pick Up Time
                          </label>
                          <Field
                            className={`form-control ${
                              formik.errors.pickUpTime &&
                              formik.touched.pickUpTime
                                ? "is-invalid"
                                : ""
                            }`}
                            name="pickUpTime"
                            id="pickUpTime"
                            type="time"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger p-0"
                            name="pickUpTime"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="destination" className="p-0">
                            Destination
                          </label>
                          <Field
                            as="select"
                            className={`form-select ${
                              formik.errors.destination &&
                              formik.touched.destination
                                ? "is-invalid"
                                : ""
                            }`}
                            name="destination"
                            id="destination"
                          >
                            <option value="">Destination Location</option>
                            <option value="GMC, 3 Hardman square, Manchester M33EB">
                              GMC, 3 Hardman Square Manchester M33EB
                            </option>
                            <option value="GMC, 3 Hardman street, Manchester M33AW">
                              GMC, 3 Hardman Street Manchester M33AW
                            </option>
                          </Field>
                          <ErrorMessage
                            component="div"
                            className="text-danger p-0"
                            name="destination"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="pickUpLocation" className="p-0">
                            Pick Up Location
                          </label>
                          {loadingHouses ? (
                            <div className="w-100 d-flex justify-content-center">
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <>
                              <Field
                                as="select"
                                className={`form-select ${
                                  formik.errors.pickUpLocation &&
                                  formik.touched.pickUpLocation
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="pickUpLocation"
                                id="pickUpLocation"
                              >
                                <option value="">Pick Up Location</option>
                                {houses.map((house: any) => (
                                  <option key={house.id} value={house.name}>
                                    {house.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component="div"
                                className="text-danger p-0"
                                name="pickUpLocation"
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        {loading ? (
                          <div className="w-100 d-flex justify-content-center">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : (
                          <>
                            <p className={"fw-bold"}>
                              Note: Taxi bookings are confirmed every thursday
                              evening. Please expect a confirmation message.
                            </p>
                            <button
                              // disabled={!formik.isValid}
                              className={`ms-2 btn btn-primary ${classes.submit_button}`}
                              type="submit"
                            >
                              Submit
                            </button>
                          </>
                        )}
                      </div>
                    </FormikForm>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamTaxiForm;
