import { graphql } from "../generated";

export const signUpMutation = graphql(`
  mutation SignUpDriver($input: CreatePassengerInput!) {
    signUpPassenger(input: $input) {
      id
    }
  }
`);

export const getMeQuery = graphql(`
  query GetMe {
    getMe {
      id
      username
      email
      phone
      role
    }
  }
`);

export const getAllBusesQuery = graphql(`
  query GetAllBuses {
    getAllBuses {
      id
      name
      plateNumber
      state
      status
      capacity
      model
      driver {
        id
        username
      }
    }
  }
`);

export const getAllRoutesQuery = graphql(`
  query GetAllRoutes {
    getAllRoutes {
      id
      name
      stops {
        id
        name
      }
    }
  }
`);

export const getAllStopsQuery = graphql(`
  query GetAllBusStops {
    getAllBusStops {
      id
      name
      address
    }
  }
`);

export const getPassengerQuery = graphql(`
  query GetPassenger($getPassengerId: ID!) {
    getPassenger(id: $getPassengerId) {
      id
      username
      booking {
        id
        house_name
        status
        check_in_date
        check_out_date
      }
    }
  }
`);

export const getPassengerBusQuery = graphql(`
  query GetPassengerBus($stopName: String!) {
    getPassengerBus(stopName: $stopName) {
      id
      name
      capacity
      plateNumber
      state
      status
      model
      driver {
        username
      }
      currentLocation {
        id
        latitude
        longitude
      }
      route {
        id
        name
        stops {
          name
          latitude
          longitude
        }
      }
    }
  }
`);

export const getBusLocationUpdateSubscription = graphql(`
  subscription Subscription($busId: ID!) {
    busLocationUpdated(busId: $busId) {
      latitude
      longitude
    }
  }
`);

export const getBusStopDistanceUpdateSubscription = graphql(`
  subscription BusStopDistanceUpdated($routeId: ID!) {
    busStopDistanceUpdated(routeId: $routeId) {
      routeId
      routeName
      busStopPoints {
        name
        distance
        duration
        status
      }
    }
  }
`);

export const getPassengerBookingsQuery = graphql(`
  query GetBookings {
    getBookings {
      id
      bed_name
      room_name
      room_type
      house_name
      initial_house_name
      house_id
      status
    }
  }
`);

export const getPassengerBookingQuery = graphql(`
  query GetBooking($getBookingId: ID!) {
    getBooking(id: $getBookingId) {
      id
      bed_name
      room_name
      room_type
      house_name
      initial_house_name
      house_id
      customer_initials
      customer_firstname
      customer_lastname
      customer_phone
      customer_email
      customer_address
      customer_country
      gender_type
      status
      bed_price
      total_amount
      caution_fee
      check_in_date
      check_out_date
      exam_date
    }
  }
`);

export const addBookingMutation = graphql(`
  mutation AddBooking($bookingId: String!) {
    addBooking(bookingId: $bookingId) {
      id
      bed_name
      room_name
      room_type
      house_name
      house_id
      status
    }
  }
`);

export const getUserTaxiBookingsQuery = graphql(`
  query GetUserTaxiBookings {
    getUserTaxiBookings {
      id
      fullName
      pickUpLocation
      pickUpTime
      status
      bookingType
      destination
      examDate
    }
  }
`);

export const createTaxiBookingMutation = graphql(`
  mutation CreateTaxiBooking(
    $fullName: String!
    $phoneNumber: String!
    $pickUpLocation: String!
    $destination: String!
    $pickUpTime: String!
    $examDate: String!
    $bookingId: String!
  ) {
    createTaxiBooking(
      fullName: $fullName
      phoneNumber: $phoneNumber
      pickUpLocation: $pickUpLocation
      destination: $destination
      pickUpTime: $pickUpTime
      examDate: $examDate
      bookingId: $bookingId
    ) {
      id
    }
  }
`);

export const createUserNotificationSubMutation = graphql(`
  mutation CreateUserSubscription(
    $endpoint: String!
    $p256Dh: String!
    $authKey: String!
  ) {
    createUserSubscription(
      endpoint: $endpoint
      p256dh: $p256Dh
      authKey: $authKey
    )
  }
`);

export const changePassengerStop = graphql(`
  mutation ChangePassengerStop($bookingId: String!, $newStop: String!) {
    changePassengerStop(bookingId: $bookingId, newStop: $newStop) {
      id
      house_name
      status
      check_in_date
      check_out_date
    }
  }
`);
