import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InstallModal from "../../components/InstallModal";
import { useAppSelector, useAppDispatch } from "../../store/app/hooks";
import { login } from "../../store/features/users/userSlice";
import axios from "../../client-axios";
import logo from "../../images/logos/plab_logo.png";
import classes from "./index.module.scss";
import { Spinner } from "react-bootstrap";
import { useGetMeQuery } from "../../graphql/hooks";
type Props = {};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const Login: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const { getMe } = useGetMeQuery();
  const user = useAppSelector((state) => state.auth.user);
  const { prompt } = useAppSelector((state) => state.installPrompt);

  if (user?.username) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className={classes.login}>
      <div className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-sm-10 col-md-8 col-lg-5">
            <div className={`card ${classes.login_card}`}>
              <div className="card-body">
                <img
                  src={logo}
                  alt="Company Logo"
                  className="img-fluid mb-4 mx-auto d-block"
                />
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={async (values) => {
                    // do form submission here
                    // console.log(values);
                    try {
                      setLoading(true);
                      const response = await axios.post("/login", {
                        email: values.email,
                        password: values.password,
                      });

                      localStorage.setItem(
                        "accessToken",
                        response.data.accessToken
                      );
                      localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                      );

                      const user = await getMe();
                      // console.log(user);
                      dispatch(login(user?.data?.getMe!));
                      setLoading(false);
                      navigate("/");
                    } catch (error: any) {
                      console.log(error);
                      toast.error(error.response.data.error);
                      setLoading(false);
                    }
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="fw-bold fs-5 text-white"
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          id="email"
                          placeholder="Enter your email"
                          aria-label="email"
                          className={`form-control ${
                            errors.email && touched.email ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="password"
                          className="fw-bold fs-5 text-white"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          id="password"
                          placeholder="Enter your password"
                          aria-label="password"
                          className={`form-control ${
                            errors.password && touched.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                      </div>
                      {loading ? (
                        <div className="d-flex justify-content-center mt-3">
                          <Spinner
                            className="spinner"
                            animation="border"
                            variant="light"
                          />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block w-100 mt-3"
                        >
                          Login
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
                <div className="d-flex justify-content-center mt-3">
                  <Link
                    to="/signup"
                    className={`${classes.login_links} text-bg-warning`}
                  >
                    New Doctor? Sign Up
                  </Link>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Link
                    to="/forgotpassword"
                    className={`${classes.login_links} text-bg-warning`}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {prompt && <InstallModal />}
    </div>
  );
};

export default Login;
