import React from "react";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import axios from "axios";
import useSWR from "swr";
import { Spinner } from "react-bootstrap";
import { detectError } from "../../utility";
import classes from "../BookingView/index.module.scss";

type Props = {};

const fetcher = (url) => axios.get(url).then((res) => res.data);
const HouseView: React.FC<Props> = (props: Props) => {
  const params = useParams<{ id: string }>();
  const { data, error, isLoading: loading } = useSWR(
    `https://plab2-prod.herokuapp.com/api/houses/${params.id}/`,
    fetcher
  );
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center">House View</h1>
        {loading && (
          <>
            <div className="d-flex justify-content-center my-2">
              <Spinner className="spinner" animation="border" variant="dark" />
            </div>
            <p className="text-center">Loading...</p>
          </>
        )}
        {error && (
          <>
            <p className="text-center text-danger">Error Loading Booking</p>
            <p className="text-center text-danger">{detectError(error)}</p>
          </>
        )}
        {!loading && !error && data && (
          <div className="d-flex justify-content-center">
            <div className={`${classes.card_special} card`}>
              <div className="card-header">
                <h5 className="card-title text-center">{data.name}</h5>
              </div>
              <div className="card-body">
                <h5 className="text-center bg-info">Location Information</h5>
                <p className="card-text">Address: {data.address}</p>
                <p className="card-text">City: {data.city}</p>
                <p className="card-text">State: {data.state}</p>
                <hr />
                <h5 className="text-center bg-info">House Information</h5>
                <p className="card-text">House Gender: {data.gender}</p>
                <p className="card-text">Wifi Password: {data.wifi_password}</p>
                <p className="card-text">Keycode: {data.keycode}</p>
                <p className="card-text">Sitting Room: {data.sitting_room}</p>
                <p className="card-text">Kitchen: {data.kitchen}</p>
                <p className="card-text">Check In Time: {data.check_in_time}</p>
                <p className="card-text">
                  Check Out Time: {data.check_out_time}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HouseView;
