import React, { useState, useEffect } from "react";
import { BsHouseCheckFill } from "react-icons/bs";
import { useGetBookingsQuery } from "../../graphql/hooks";
import BackButton from "../../components/BackButton";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { detectError } from "../../utility";

type Props = {};

const Houses: React.FC<Props> = (props: Props) => {
  const { loading, bookings, error } = useGetBookingsQuery();
  const [houses, setHouses] = useState<any>([]);

  useEffect(() => {
    if (bookings) {
      const uniqHouses = Array.from(
        new Set(bookings.map((booking) => booking.house_id))
      );
      const bks: any[] = [];
      for (let i = 0; i < uniqHouses.length; i++) {
        bks.push(bookings.find((b) => b.house_id === uniqHouses[i]));
      }

      setHouses(bks);
    }
  }, [bookings]);
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center">Your Houses</h1>
        {loading && (
          <>
            <div className="d-flex justify-content-center my-2">
              <Spinner className="spinner" animation="border" variant="dark" />
            </div>
            <p className="text-center">Loading Your Houses...</p>
          </>
        )}
        {error && (
          <>
            <p className="text-center text-danger">Error Loading Houses</p>
            <p className="text-center text-danger">{detectError(error)}</p>
          </>
        )}
        {!loading && !error && bookings && bookings.length === 0 && (
          <p className="text-center">No Available House</p>
        )}
        {!loading && !error && houses && houses.length > 0 && (
          <>
            <div className="row g-3">
              {houses.map((booking) => (
                <div className="col-12 col-lg-4" key={booking.id}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          height: "7rem",
                        }}
                        className="d-flex justify-content-center align-items-center flex-column"
                      >
                        <BsHouseCheckFill fontSize="40" />
                        <p className="fw-bold fs-4">
                          {booking.initial_house_name
                            ? booking.initial_house_name
                            : booking.house_name}
                        </p>
                      </div>
                      <Link
                        to={booking.house_id}
                        className="btn btn-secondary w-100"
                      >
                        View House
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Houses;
