import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/app/hooks";
import React from "react";

type Props = {
  Component: any;
};

const AuthenticatedRoute: React.FC<Props> = (props) => {
  const user = useAppSelector((state) => state.auth.user);
  // let userRoute = (
  //   <props.Component
  //     history={props.history}
  //     location={props.location}
  //     match={props.match}
  //   />
  // );

  let userRoute;

  if (user) {
    userRoute = <props.Component />;
  } else {
    userRoute = (
      <Navigate
        to={"login"}
        // state={{ from: props.location.pathname }}
        // state: { referrer: props.location.pathname }
      />
    );
  }
  return userRoute;
};

export default AuthenticatedRoute;
