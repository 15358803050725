import React from "react";
import BackButton from "../../components/BackButton";
import { Link } from "react-router-dom";
import { ReactComponent as ExamIcon } from "../../images/svgs/exam.svg";
// import { ReactComponent as AirportIcon } from "../../images/svgs/airport.svg";
import classes from "../../components/Mainboard/index.module.scss";
import { useGetUserTaxiBookingsQuery } from "../../graphql/hooks";
import { Spinner } from "react-bootstrap";
import { detectError } from "../../utility";

type Props = {};

const BookTaxi: React.FC<Props> = (props: Props) => {
  const { loading, bookings, error } = useGetUserTaxiBookingsQuery();
  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <p className="text-center text-danger fw-bold mb-3 fs-5">
          Taxi reservations must be made at least a week before pick up date!
        </p>
        <p className="text-center text-danger fw-bold mb-3 fs-5">
          You have one free taxi booking for each Plab2 accommodation booking
          and taxi bookings are linked to your booking.
        </p>
        <div className="row g-3 p-3">
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="d-flex  justify-content-center align-items-center">
              <Link to="exam">
                <div className={`${classes.mainboard_card} card`}>
                  <div className="card-body">
                    <ExamIcon className="img-fluid mb-4 mx-auto d-block" />
                    <h5 className="card-title text-center fw-bold text-bg-warning">
                      Book Taxi for Exam
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {/*<div className="col-12 col-sm-6 col-xl-4">*/}
          {/*  <div className="d-flex  justify-content-center align-items-center">*/}
          {/*    <Link to="airport">*/}
          {/*      <div className={`${classes.mainboard_card} card`}>*/}
          {/*        <div className="card-body">*/}
          {/*          <AirportIcon className="img-fluid mb-4 mx-auto d-block" />*/}
          {/*          <h5 className="card-title text-center fw-bold text-bg-warning">*/}
          {/*            Book Taxi to Airport*/}
          {/*          </h5>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="mt-5">
          <h1 className="text-center">Your Bookings</h1>
          {loading && (
            <>
              <div className="d-flex justify-content-center my-2">
                <Spinner
                  className="spinner"
                  animation="border"
                  variant="dark"
                />
              </div>
              <p className="text-center">Loading Your Bookings...</p>
            </>
          )}
          {error && (
            <>
              <p className="text-center text-danger">Error Loading Bookings</p>
              <p className="text-center text-danger">{detectError(error)}</p>
            </>
          )}
          {!loading && !error && bookings && bookings.length === 0 && (
            <p className="text-center">No Available Bookings</p>
          )}
          {!loading && !error && bookings && bookings.length > 0 && (
            <>
              <div className="row g-3">
                {bookings.map((booking) => (
                  <div className="col-12 col-lg-4" key={booking.id}>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title text-center">
                          {booking.bookingType === "exam"
                            ? "Exam Booking"
                            : "Airport Booking"}
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="row g-1">
                          <div className="col-6">
                            <span className="badge bg-warning">
                              Pick Up Location
                            </span>
                            <p className="card-text">
                              {booking.pickUpLocation}
                            </p>
                          </div>
                          <div className="col-6">
                            <span className="badge bg-warning">
                              Destination
                            </span>
                            <p className="card-text">{booking.destination}</p>
                          </div>
                          <div className="col-6">
                            <span className="badge bg-warning">
                              Pick Up Date
                            </span>
                            <p className="card-text">{booking.examDate}</p>
                          </div>
                          <div className="col-6">
                            <span className="badge bg-warning">
                              Pick Up Time
                            </span>
                            <p className="card-text">{booking.pickUpTime}</p>
                          </div>
                          <div className="col-6">
                            <span className="badge bg-warning">Status</span>
                            <p className="card-text">{booking.status}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookTaxi;
