import React from "react";
import { useAppSelector, useAppDispatch } from "../store/app/hooks";
import { setPrompt } from "../store/features/installPrompt/promptSlice";

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [
  IBeforeInstallPromptEvent | null,
  () => void
] {
  const dispatch = useAppDispatch();
  const { prompt } = useAppSelector((state) => state.installPrompt);
  // const [prompt, setState] = React.useState<IBeforeInstallPromptEvent | null>(
  //     null
  // );

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  React.useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      dispatch(setPrompt(e));
    };

    window.addEventListener("beforeinstallprompt", ready as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [prompt, promptToInstall];
}
