import { ApolloError } from "@apollo/client";

export const detectError = (graphQLError: ApolloError) => {
  let errMsgs: string[] = [];
  // Apollo Client errors are usually under graphQLErrors
  if (graphQLError.graphQLErrors) {
    graphQLError.graphQLErrors.forEach(({ message, extensions }) => {
      const errorStr = `${extensions?.code}: ${message}`;
      errMsgs.push(errorStr);
    });
  }

  // Network errors are under networkError
  if (graphQLError.networkError) {
    const error = graphQLError.networkError as any;
    let message = "";
    if (error?.result?.error) {
      message = error.result.error;
    } else {
      message = `[Network error]: ${graphQLError.networkError.message}`;
    }
    errMsgs.push(message);
  }

  // Message of last resort
  if (!errMsgs.length) {
    errMsgs.push(graphQLError.message);
  }
  let messages = "";
  if (errMsgs.length > 1)
    messages = errMsgs.map((msg, i) => `${i + 1}. ${msg}`).join("\n");
  else messages = errMsgs[0];

  return messages;
};

export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
