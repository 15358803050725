import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/app/hooks";
import { useNavigate } from "react-router-dom";
import { useGetPassengerBusQuery } from "../../graphql/hooks";
import {
  setBus,
  setCurrentLocation,
  setDriver,
  setRoute,
} from "../../store/features/bus/busSlice";
import { toast } from "react-toastify";
import BusDetail from "../../components/BusDetail";

type Props = {};

const RouteDetails: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeStop = useAppSelector((state) => state.auth.activeStop);
  const { buses, loading, error } = useGetPassengerBusQuery(activeStop);

  const chooseRoute = (busId: string) => {
    if (buses && buses.length > 0) {
      const bus = buses.find((bus) => bus?.id === busId);
      if (bus) {
        dispatch(setBus(bus));
        dispatch(setDriver(bus.driver!));
        dispatch(setCurrentLocation(bus.currentLocation!));
        dispatch(setRoute(bus.route!));
        navigate("/tracking/routedistance");
      }
    } else {
      toast.error("Something went wrong... Please refresh page");
    }
  };

  return (
    <BusDetail
      loading={loading}
      error={error}
      buses={buses}
      activeStop={activeStop}
      chooseFunction={chooseRoute}
    />
  );
};

export default RouteDetails;
