import React, { useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Route, Routes } from "react-router-dom";
import classes from "./index.module.scss";
import MainBoard from "../../components/Mainboard";
import NotFound from "../../components/NotFound";
import Footer from "../../components/Footer";
import BusTracking from "../../components/BusTracking";
import BusDetails from "../BusDetails";
import TrackingMap from "../TrackingMap";
import RouteDistance from "../RouteDistance";
import RouteDetails from "../RouteDetails";
import Bookings from "../Bookings";
import BookingView from "../BookingView";
import AddBooking from "../AddBooking";
import Houses from "../Houses";
import HouseView from "../HouseView";
import Refund from "../Refund";
import RefundView from "../RefundView";
import BookTaxi from "../BookTaxi";
import ExamTaxi from "../ExamTaxi";
import ExamTaxiForm from "../ExamTaxiForm";
import Others from "../Others";

type Props = {};

const UserHome: React.FC<Props> = (props) => {
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const makeActive = (event: any) => {
    const sidebar = document.getElementById("sidebar");

    if (sidebar?.classList.contains("sidebar_on")) {
      sidebar?.classList.remove("sidebar_on");
    }
    // sidebar?.classList.toggle("sidebar_on");
  };
  return (
    <>
      <Header />
      <Sidebar />
      <div className={classes.main_container} onClick={makeActive}>
        {/*<div className="container">*/}
        <Routes>
          <Route path={""} element={<MainBoard />} />
          <Route path={"tracking"} element={<BusTracking />} />
          <Route path={"tracking/details"} element={<BusDetails />} />
          <Route path={"tracking/route"} element={<RouteDetails />} />
          <Route path={"tracking/map"} element={<TrackingMap />} />
          <Route path={"tracking/routedistance"} element={<RouteDistance />} />
          <Route path={"bookings"} element={<Bookings />} />
          <Route path={"bookings/:id"} element={<BookingView />} />
          <Route path={"bookings/new"} element={<AddBooking />} />
          <Route path={"houses"} element={<Houses />} />
          <Route path={"houses/:id"} element={<HouseView />} />
          <Route path={"refunds"} element={<Refund />} />
          <Route path={"refunds/:id"} element={<RefundView />} />
          <Route path={"booktaxi"} element={<BookTaxi />} />
          <Route path={"booktaxi/exam"} element={<ExamTaxi />} />
          <Route path={"booktaxi/exam/:id"} element={<ExamTaxiForm />} />
          <Route path={"others"} element={<Others />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/*</div>*/}
      </div>
      <Footer />
    </>
  );
};

export default UserHome;
