import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { FaHouseUser } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { useChangePassengerStopMutation } from "../../graphql/hooks";
import { useAppSelector, useAppDispatch } from "../../store/app/hooks";
import { setActiveStop } from "../../store/features/users/userSlice";
import { setHouses } from "../../store/features/houses/houseSlice";
import { detectError } from "../../utility";
import classes from "./index.module.scss";
type Props = {
  loadingData: boolean;
};

const PassengerDetails: React.FC<Props> = ({ loadingData }) => {
  const [show, setShow] = useState(false);
  const [stop, setStop] = useState("");
  const [loadingHouses, setLoadingHouses] = useState(false);
  const dispatch = useAppDispatch();
  const { activeStop, bookingId, status, bookingDays } = useAppSelector(
    (state) => state.auth
  );

  const houses = useAppSelector((state) => state.houseData.houses);
  const { changeStop, loading } = useChangePassengerStopMutation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchHouses = async () => {
      try {
        setLoadingHouses(true);
        const response = await axios.get(
          "https://plab2-prod.herokuapp.com/api/houselist"
        );
        dispatch(setHouses(response.data));
        setLoadingHouses(false);
      } catch (e) {
        console.log(e);
        setLoadingHouses(false);
      }
    };

    if (!houses || houses.length === 0) {
      fetchHouses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100 h-100">
      {/*<div className="container">*/}
      <div className={`${classes.row_div} row g-3`}>
        <div className={`col-12 col-sm-4 ${classes.column}`}>
          <div className={`card ${classes.card_div}`}>
            <div className="card-body">
              <div className={classes.card_div_icon}>
                <BsFillCalendarCheckFill className="text-center" size="25" />
              </div>
              <h5 className="card-title text-center">Booking</h5>
              {loadingData && (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Spinner
                    className="spinner"
                    animation="border"
                    variant="dark"
                  />
                </div>
              )}
              {!loadingData && (
                <p className="card-text text-center">
                  {bookingDays === -1 && "Not Started"}
                  {bookingDays === 0 && "Ended"}
                  {bookingDays === 1 && "Check Out Day"}
                  {bookingDays === 2 && (
                    <>
                      <div>
                        <div>{bookingDays} days</div>
                        <div className={classes.check_out}>
                          Check Out Tomorrow
                        </div>
                      </div>
                    </>
                  )}
                  {bookingDays > 2 && `${bookingDays} days`}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={`col-12 col-sm-4 ${classes.column}`}>
          <div className={`card ${classes.card_div}`}>
            <div className="card-body">
              <div className={classes.card_div_icon}>
                <FaHouseUser className="text-center" size="25" />
              </div>
              <h5 className="card-title text-center">Stop</h5>
              {loadingData && (
                <div className="d-flex justify-content-center align-items-center h-100 mt-3">
                  <Spinner
                    className="spinner"
                    animation="border"
                    variant="dark"
                  />
                </div>
              )}
              {!loadingData && (
                <>
                  <p className="card-text text-center">{activeStop}</p>
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={handleShow}
                      className="btn btn-primary btn-sm"
                    >
                      Change
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`col-12 col-sm-4 ${classes.column}`}>
          <div className={`card ${classes.card_div}`}>
            <div className="card-body">
              <div className={classes.card_div_icon}>
                <GrStatusGood className="text-center" size="25" />
              </div>
              <h5 className="card-title text-center">Status</h5>
              {loadingData && (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Spinner
                    className="spinner"
                    animation="border"
                    variant="dark"
                  />
                </div>
              )}
              {!loadingData && (
                <p className="card-text text-center">{status}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*</div>*/}
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Stop</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p className="text-danger fw-bold">
                Only change your house if you were moved to another house.
              </p>
              <label htmlFor="stop" className="p-0">
                Select House You are Living In
              </label>
              {loadingHouses ? (
                <div className="w-100 d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <select
                    onChange={(e) => setStop(e.target.value)}
                    value={stop}
                    className="form-select"
                    name="stop"
                    id="stop"
                  >
                    <option value="">Stops</option>
                    {houses &&
                      houses.map((house: any) => (
                        <option key={house.name} value={house.name}>
                          {house.name}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={loading}
              onClick={async () => {
                if (!stop) return toast.error("Please select a stop");
                try {
                  await changeStop({
                    variables: {
                      bookingId,
                      newStop: stop,
                    },
                  });
                  dispatch(setActiveStop(stop));
                  handleClose();
                } catch (e: any) {
                  toast.error(detectError(e));
                }
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PassengerDetails;
