import { configureStore } from "@reduxjs/toolkit";
import throttle from "lodash/throttle";
import { loadState, saveState } from "../localStorage";
import userReducer from "../features/users/userSlice";
import busReducer from "../features/bus/busSlice";
import houseReducer from "../features/houses/houseSlice";
import installPromptReducer from "../features/installPrompt/promptSlice";

const preloadedState = loadState();
export const store = configureStore({
  reducer: {
    auth: userReducer,
    installPrompt: installPromptReducer,
    busDetails: busReducer,
    houseData: houseReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["installBanner/setPrompt"],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ["installPrompt.prompt"],
      },
    }),
});

store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
      busDetails: store.getState().busDetails,
    });
  }, 1000)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
