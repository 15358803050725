import React from "react";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useGetBookingQuery } from "../../graphql/hooks";
import { Spinner } from "react-bootstrap";
import { detectError } from "../../utility";
import classes from "./index.module.scss";

type Props = {};

type Param = {
  id: string;
};
const BookingView: React.FC<Props> = (props: Props) => {
  const params = useParams<Param>();
  const { loading, booking, error } = useGetBookingQuery(params.id!);

  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        {loading && (
          <>
            <div className="d-flex justify-content-center my-2">
              <Spinner className="spinner" animation="border" variant="dark" />
            </div>
            <p className="text-center">Loading...</p>
          </>
        )}
        {error && (
          <>
            <p className="text-center text-danger">Error Loading Booking</p>
            <p className="text-center text-danger">{detectError(error)}</p>
          </>
        )}
        {booking && (
          <div className="d-flex justify-content-center">
            <div className={`${classes.card_special} card`}>
              <div className="card-header">
                <h5 className="card-title text-center">
                  {booking.initial_house_name
                    ? booking.initial_house_name
                    : booking.house_name}
                </h5>
              </div>
              <div className="card-body">
                <h5 className="text-center bg-info">Room Information</h5>
                <p className="card-text">Room Name: {booking.room_name}</p>
                <p className="card-text">Room Type: {booking.room_type}</p>
                <p className="card-text">Bed Name: {booking.bed_name}</p>
                <p className="card-text">House Gender: {booking.gender_type}</p>
                <hr />
                <h5 className="text-center bg-info">Customer Information</h5>
                <p className="card-text">
                  Initials: {booking.customer_initials}
                </p>
                <p className="card-text">
                  First Name: {booking.customer_firstname}
                </p>
                <p className="card-text">
                  Last Name: {booking.customer_lastname}
                </p>
                <p className="card-text">Phone: {booking.customer_phone}</p>
                <p className="card-text">Email: {booking.customer_email}</p>
                <p className="card-text">Country: {booking.customer_country}</p>
                <hr />
                <h5 className="text-center bg-info">Price Information</h5>
                <p className="card-text">Bed Price: {booking.bed_price}GBP</p>
                <p className="card-text">
                  Caution Fee: {booking.caution_fee}GBP
                </p>
                <p className="card-text">
                  Cleaning Fee:{" "}
                  {(Math.ceil(
                    (new Date(booking.check_out_date).getTime() -
                      new Date(booking.check_in_date).getTime()) /
                      (1000 * 3600 * 24)
                  ) +
                    1) *
                    3}
                  GBP
                </p>
                <p className="card-text">
                  Total Amount: {booking.total_amount}GBP
                </p>
                <hr />
                <h5 className="text-center bg-info">Booking Details</h5>
                <p className="card-text">Check In: {booking.check_in_date}</p>
                <p className="card-text">Check Out: {booking.check_out_date}</p>
                <p className="card-text">
                  Duration:{" "}
                  {Math.ceil(
                    (new Date(booking.check_out_date).getTime() -
                      new Date(booking.check_in_date).getTime()) /
                      (1000 * 3600 * 24)
                  ) + 1}{" "}
                  days
                </p>
                <p className="card-text">Exam Date: {booking.exam_date}</p>
                <p className="card-text">Booking Status: {booking.status}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingView;
