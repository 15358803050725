import {
  useMutation,
  useLazyQuery,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  getAllRoutesQuery,
  getMeQuery,
  signUpMutation,
  getAllStopsQuery,
  getPassengerBusQuery,
  getPassengerQuery,
  getBusLocationUpdateSubscription,
  getBusStopDistanceUpdateSubscription,
  getPassengerBookingsQuery,
  getPassengerBookingQuery,
  addBookingMutation,
  getUserTaxiBookingsQuery,
  createTaxiBookingMutation,
  createUserNotificationSubMutation,
  changePassengerStop,
} from "./queries";

export function useSignUpMutation() {
  const [signUp, { error, loading, data }] = useMutation(signUpMutation);

  return {
    signUp,
    error,
    loading,
    data,
  };
}

export function useGetMeQuery() {
  const [getMe, { error, loading, data }] = useLazyQuery(getMeQuery, {
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    data,
    getMe,
  };
}

export function useGetAllRoutesQuery() {
  const { error, loading, data } = useQuery(getAllRoutesQuery, {
    // fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    routes: data?.getAllRoutes,
  };
}

export function useGetAllStopsQuery() {
  const { error, loading, data } = useQuery(getAllStopsQuery, {
    // fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    stops: data?.getAllBusStops,
  };
}

export function useGetPassengerDetailsQuery(id: string) {
  const { error, loading, data } = useQuery(getPassengerQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      getPassengerId: id,
    },
  });

  const bookings = data?.getPassenger?.booking;
  let activeStop = "";
  let status = "";
  let bookingDays = 0;
  let bookingId = "";
  if (bookings && bookings.length > 0) {
    const activeBookings = bookings.filter(
      (booking) => booking.status === "booked"
    );

    if (activeBookings.length >= 1) {
      if (activeBookings.length > 1) {
        activeBookings.sort((a, b) => {
          return (
            new Date(a.check_in_date).getTime() -
            new Date(b.check_in_date).getTime()
          );
        });
      }
      activeStop = activeBookings[0].house_name;
      status = activeBookings[0].status;
      const today = new Date();
      const check_out = new Date(activeBookings[0].check_out_date);
      const check_in = new Date(activeBookings[0].check_in_date);
      const bookingStarted = check_in.getTime() < today.getTime();
      bookingId = activeBookings[0].id;
      if (bookingStarted) {
        bookingDays =
          Math.ceil(
            (check_out.getTime() - today.getTime()) / (1000 * 3600 * 24)
          ) + 1;
      } else {
        bookingDays = -1;
      }
    } else {
      const otherBookings = bookings
        .filter((booking) => booking.status !== "booked")
        .sort((a, b) => {
          return (
            new Date(b.check_in_date).getTime() -
            new Date(a.check_in_date).getTime()
          );
        });
      activeStop = otherBookings[0].house_name;
      status = otherBookings[0].status;
      bookingDays = 0;
      bookingId = otherBookings[0].id;
    }
  }

  return {
    error,
    loading,
    activeStop,
    status,
    bookingDays,
    bookingId,
  };
}

export function useGetPassengerBusQuery(stopName: string) {
  const { error, loading, data } = useQuery(getPassengerBusQuery, {
    fetchPolicy: "network-only",
    variables: {
      stopName,
    },
  });

  return {
    error,
    loading,
    buses: data?.getPassengerBus,
  };
}

export function useBusLocationUpdatedSubscription(busId: string) {
  const { error, loading, data } = useSubscription(
    getBusLocationUpdateSubscription,
    {
      fetchPolicy: "network-only",
      variables: {
        busId,
      },
    }
  );

  const currentLocation = data?.busLocationUpdated;

  return {
    error,
    loading,
    currentLocation,
  };
}

export function useBusStopDistanceUpdatedSubscription(routeId: string) {
  const { error, loading, data } = useSubscription(
    getBusStopDistanceUpdateSubscription,
    {
      fetchPolicy: "network-only",
      variables: {
        routeId,
      },
    }
  );

  const routeDistance = data?.busStopDistanceUpdated;

  return {
    error,
    loading,
    routeDistance,
  };
}

export function useGetBookingsQuery() {
  const { error, loading, data } = useQuery(getPassengerBookingsQuery, {
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "cache-first",
  });

  return {
    error,
    loading,
    bookings: data?.getBookings,
  };
}

export function useGetBookingQuery(bookingId: string) {
  const { error, loading, data } = useQuery(getPassengerBookingQuery, {
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "cache-first",
    variables: {
      getBookingId: bookingId,
    },
  });

  return {
    error,
    loading,
    booking: data?.getBooking,
  };
}

export function useAddBookingMutation() {
  const [mutate, { error, loading, data }] = useMutation(addBookingMutation);

  const addNewBooking = async (bookingId: string) => {
    await mutate({
      variables: {
        bookingId,
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getBookings(existingBookings, { toReference }) {
              // @ts-ignore
              return [...existingBookings, toReference(data?.addBooking)];
            },
          },
        });
      },
    });
  };

  return {
    addNewBooking,
    error,
    loading,
    data,
  };
}

export function useGetUserTaxiBookingsQuery() {
  const { error, loading, data } = useQuery(getUserTaxiBookingsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  return {
    error,
    loading,
    bookings: data?.getUserTaxiBookings,
  };
}

export function useCreateTaxiBookingMutation() {
  const [createTaxiBooking, { error, loading, data }] = useMutation(
    createTaxiBookingMutation
  );

  return {
    createTaxiBooking,
    error,
    loading,
    data,
  };
}

export function useAddNotificationSubMutation() {
  const [addNotificationSub, { error, loading, data }] = useMutation(
    createUserNotificationSubMutation
  );

  return {
    addNotificationSub,
    error,
    loading,
    data,
  };
}

export function useChangePassengerStopMutation() {
  const [changeStop, { error, loading, data }] = useMutation(
    changePassengerStop
  );

  return {
    changeStop,
    error,
    loading,
    data,
  };
}
