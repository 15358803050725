import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useAppSelector } from "../../store/app/hooks";
import { useBusLocationUpdatedSubscription } from "../../graphql/hooks";
import classes from "./index.module.scss";
import BackButton from "../../components/BackButton";
import { toast } from "react-toastify";
import { detectError } from "../../utility";

type Props = {};

const containerStyle = {
  width: "100vw",
  height: "100%",
};

const TrackingMap: React.FC<Props> = (props: Props) => {
  // const center = useRef({ lat: 53.483002, lng: -2.2931 });
  const { bus, currentLocation, route } = useAppSelector(
    (state) => state.busDetails
  );
  const [center, setCenter] = useState({
    lat: currentLocation!.latitude,
    lng: currentLocation!.longitude,
  });
  const {
    currentLocation: updatedLocation,
    error,
  } = useBusLocationUpdatedSubscription(bus!.id);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
  });

  useEffect(() => {
    if (updatedLocation) {
      setCenter({
        lat: updatedLocation.latitude,
        lng: updatedLocation.longitude,
      });
    }
  }, [updatedLocation]);

  // console.log("center", center);

  if (error) {
    toast.error(detectError(error));
  }

  if (loadError)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <p className="text-center fs-6 fw-bold">
          Map cannot be loaded right now, sorry.
        </p>
      </div>
    );
  if (!isLoaded)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <p className="text-center fs-6 fw-bold">Loading...</p>
      </div>
    );

  return (
    <div
      className={`${classes.map} w-100`}
      // style={{ height: "100vh", width: "100%" }}
    >
      <BackButton extraClasses={classes.map_back_button} />
      <GoogleMap zoom={16} center={center} mapContainerStyle={containerStyle}>
        <Marker
          position={center}
          label={{
            text: "Plab2 Car",
            className: classes.map_marker_label,
          }}
          icon={{
            url: require("../../images/svgs/parking-point-icon.svg").default,
            scaledSize: { width: 40, height: 40, equals: () => true },
          }}
        />
        {route &&
          route.stops.map((stop, index) => (
            <Marker
              key={index}
              position={{
                lat: stop.latitude,
                lng: stop.longitude,
              }}
              label={{
                text: `Stop: ${stop.name}`,
                className: classes.map_marker_label,
                color: "#fff",
              }}
              icon={{
                url: require("../../images/svgs/house_marker.svg").default,
                scaledSize: { width: 40, height: 40, equals: () => true },
              }}
            />
          ))}
      </GoogleMap>
    </div>
  );
};

export default TrackingMap;
