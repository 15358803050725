import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BsList } from "react-icons/bs";
import { BiExit } from "react-icons/bi";
import logo from "../../images/logos/plab_logo.png";
import classes from "./index.module.scss";
import { useAppDispatch } from "../../store/app/hooks";
import { logout } from "../../store/features/users/userSlice";
interface Props {}

export const Header: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const makeActive = (event: any) => {
    event.preventDefault();
    const sidebar = document.getElementById("sidebar");

    sidebar?.classList.toggle("sidebar_on");
  };

  return (
    <Navbar collapseOnSelect fixed="top" bg="dark" variant="dark">
      {/* <Container> */}
      <div className="w-100 d-flex justify-content-between px-3">
        <Navbar.Brand>
          <img
            alt="logo"
            src={logo}
            width="150"
            height="50"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Nav className="ms-auto d-flex align-items-center">
          <div className="my-3 text-dark d-flex align-items-center me-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(logout())}
              className="text-light"
            >
              <BiExit />
              <span className="ms-2 d-none d-sm-inline-block text-light">
                Logout
              </span>
            </div>
          </div>
          <div
            onClick={makeActive}
            className={`d-flex justify-content-center align-items-center ${classes.burger}`}
          >
            <BsList />
          </div>
        </Nav>
      </div>
      {/* </Container> */}
    </Navbar>
  );
};
export default Header;
