import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { BsHouseFill } from "react-icons/bs";
import { FaCar, FaTaxi } from "react-icons/fa";
import { BsTicketPerforatedFill, BsHouseCheckFill } from "react-icons/bs";
import { CgTrack } from "react-icons/cg";
import { HiReceiptRefund } from "react-icons/hi";

import { ReactComponent as PersonIcon } from "../../images/svgs/person.svg";
import classes from "./index.module.scss";
import { useAppSelector, useAppDispatch } from "../../store/app/hooks";
import { logout } from "../../store/features/users/userSlice";
import { useAddToHomescreenPrompt } from "../../hooks/useAddToHomeScreenPrompt";

interface Props {}

export const Sidebar: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const { prompt } = useAppSelector((state) => state.installPrompt);
  const [, promptToInstall] = useAddToHomescreenPrompt();

  const makeActive = (event: any) => {
    event.preventDefault();
    const sidebar = document.getElementById("sidebar");

    sidebar?.classList.toggle("sidebar_on");
  };

  return (
    <>
      <Nav
        id="sidebar"
        className={` ${classes.side_bar} d-flex flex-column flex-nowrap align-items-start pt-5 py-3`}
        onClick={makeActive}
      >
        <div className="w-100">
          <div className={classes.side_bar_profile}>
            <PersonIcon className={classes.side_bar_profile_image} />
          </div>
          <h5 className="text-center w-100">{user?.username}</h5>
          <p className="text-center w-100">{user?.email}</p>
        </div>
        <hr className="bg-dark w-100" />
        <Nav.Item className={`${classes["nav-item"]} w-100 p-0 my-1 ps-3`}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? `${classes.sd_active} d-flex align-items-center w-100 p-2`
                : `${classes.sd_inactive} d-flex align-items-center w-100 p-2`
            }
            to={"/"}
          >
            <BsHouseFill /> <span className="ms-2">Home</span>
          </NavLink>
        </Nav.Item>
        <hr className="bg-dark w-100" />
        <Nav.Item className={`${classes["nav-item"]} w-100 p-0 my-1 ps-3`}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? `${classes.sd_active} d-flex align-items-center w-100 p-2`
                : `${classes.sd_inactive} d-flex align-items-center w-100 p-2`
            }
            to={"tracking"}
          >
            <FaCar /> <CgTrack />
            <span className="ms-2">Bus Tracking</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item className={`${classes["nav-item"]} w-100 p-0 my-1 ps-3`}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? `${classes.sd_active} d-flex align-items-center w-100 p-2`
                : `${classes.sd_inactive} d-flex align-items-center w-100 p-2`
            }
            // exact
            to={"bookings"}
          >
            <BsTicketPerforatedFill /> <span className="ms-2">Bookings</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item className={`${classes["nav-item"]} w-100 p-0 my-1 ps-3`}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? `${classes.sd_active} d-flex align-items-center w-100 p-2`
                : `${classes.sd_inactive} d-flex align-items-center w-100 p-2`
            }
            // exact
            to={"/houses"}
          >
            <BsHouseCheckFill /> <span className="ms-2">House Info</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item className={`${classes["nav-item"]} w-100 p-0 my-1 ps-3`}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? `${classes.sd_active} d-flex align-items-center w-100 p-2`
                : `${classes.sd_inactive} d-flex align-items-center w-100 p-2`
            }
            // exact
            to={"/refunds"}
          >
            <HiReceiptRefund /> <span className="ms-2">Request Refund</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item className={`${classes["nav-item"]} w-100 p-0 my-1 ps-3`}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? `${classes.sd_active} d-flex align-items-center w-100 p-2`
                : `${classes.sd_inactive} d-flex align-items-center w-100 p-2`
            }
            // exact
            to={"/booktaxi"}
          >
            <FaTaxi /> <span className="ms-2">Book Taxi</span>
          </NavLink>
        </Nav.Item>
        {prompt && (
          <button
            className="btn btn-success mt-5 mb-3 ms-3"
            onClick={() => promptToInstall()}
          >
            Install as Mobile App
          </button>
        )}
        <button
          className="btn btn-warning mt-5 ms-3"
          onClick={() => dispatch(logout())}
        >
          Logout
        </button>
      </Nav>
    </>
  );
};
export default Sidebar;
