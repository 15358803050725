import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import classes from "./index.module.scss";
import { useAddBookingMutation } from "../../graphql/hooks";
import { toast } from "react-toastify";
import { detectError } from "../../utility";
type Props = {};

const AddBooking: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [booking, setBooking] = useState("");
  const { addNewBooking, loading } = useAddBookingMutation();
  const bookingHandler = async () => {
    if (!booking) {
      toast.error("Please add your booking ID");
      return;
    }
    try {
      await addNewBooking(booking);
      toast.success("Booking Added Successfully");
      navigate("/bookings");
    } catch (e: any) {
      console.log(e);
      toast.error(detectError(e));
    }
  };

  return (
    <div className="w-100 h-100">
      <div className="container">
        <BackButton />
        <h1 className="text-center">Add New Booking</h1>
        <div className="d-flex justify-content-center">
          <div className={`${classes.booking} card`}>
            <div className="card-body">
              <label htmlFor="booking" className="mb-3 w-100 text-center">
                Receipt ID
              </label>
              <input
                type="text"
                className="form-control mb-4"
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                value={booking}
                onChange={(e) => setBooking(e.target.value)}
              />
              <button
                onClick={bookingHandler}
                disabled={loading}
                className="btn btn-success w-100"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBooking;
